@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./Roboto-Regular.ttf") format("truetype");
}

body,
html {
  height: 100%;
  margin: 0;
  font-family: Roboto, Helvetica, sans-serif;
  user-select: none;
}
#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
}
.sidebar {
  flex: 1;
  max-width: 300px;
}
.popup {
  width: 140px;
  position: absolute;
  bottom: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 0px hsla(0, 0%, 0%, 0.2);
  font-size: 90%;
  padding-top: 8px;

  overflow: hidden;
  .head {
    font-weight: bold;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .statusWrapper {
    &.error {
      background: #fbf2f2;
      .status .dot {
        background-color: red;
      }
    }
    padding: 4px;
    .status {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      margin-top: 8px;
      .dot {
        width: 10px;
        height: 10px;
        background-color: green;
        border-radius: 50%;
        margin-right: 8px;
        margin-left: 12px;
      }
    }
    .text {
      font-size: 90%;
      padding-left: 8px;
    }
  }
}

.fakeText {
  flex: 1;
  margin: 4px;
  height: 16px;
  background-color: #ddd;
  border-radius: 8px;
  &.head {
    background-color: #aaa;
  }
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: white;
  width: 10%;
  min-width: 300px;
  margin: 0 auto;
  border-radius: 10px;
  height: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  zoom: 2;
}

.sidebar {
  position: absolute;
  left: 12px;
  top: 12px;
  width: 300px;
  background-color: white;
  border-radius: 5px;
  padding: 12px;
  pointer-events: all;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.htmlIcon {
  background-color: white;
  display: flex;
  width: 250px;
  height: 50px;
  margin-bottom: 5px;
  font-size: 80%;
  cursor: pointer;
  transition: opacity 1s;
  
  .icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 150%;
  }
  .text{
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}
